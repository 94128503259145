import React, { useState, useEffect, useCallback, Fragment } from 'react'
import styled, { css } from 'styled-components'

import {
  getFirebaseFirestore,
  getFirebaseStorage,
} from '@babelcoder/gatsby-plugin-firebase'

const LIMIT = 10

const CourseList = styled.section`
  width: 992px;
  margin: 0 auto;
`

const CourseItem = styled.div`
  display: flex;
  justify-content: space-between;
`

const CourseItemImage = styled.img`
  width: 100px;
`

const SearchByDateInput = styled.input``

const NextButton = styled.button``

export default () => {
  const [subscriptions, setSubscriptions] = useState([])
  const [date, setDate] = useState('')
  const [dateCursor, setDateCursor] = useState(new Date())

  const fetchSubscriptions = useCallback(
    async (dateCursor) => {
      const firestore = await getFirebaseFirestore()
      const querySnapshot = await firestore
        .collectionGroup('subscriptions')
        .orderBy('createdAt', 'desc')
        .where('createdAt', '<', dateCursor)
        .limit(LIMIT)
        .get()
      const count = querySnapshot.size
      const subscriptions = await Promise.all(
        querySnapshot.docs.map(async (doc, index) => {
          const { uid, email, createdAt } = doc.data()
          if (index === count - 1) {
            setDateCursor(createdAt.toDate())
          }

          return {
            id: doc.id,
            uid,
            email,
            createdAt: createdAt.toDate().toLocaleDateString(),
          }
        })
      )
      setSubscriptions(subscriptions)
    },
    [getFirebaseFirestore, getFirebaseStorage, setSubscriptions, setDateCursor]
  )

  const changeDate = (event) => {
    setDate(event.target.value)
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      if (date === '') fetchSubscriptions(dateCursor)
      else searchByDateInput()
    }
  }

  const searchByDateInput = async () => {
    const firestore = await getFirebaseFirestore()
    const startDate = new Date(date)
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    const endDate = new Date(date)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)
    const querySnapshot = await firestore
      .collectionGroup('subscriptions')
      .orderBy('createdAt', 'desc')
      .where('createdAt', '>=', startDate)
      .where('createdAt', '<=', endDate)
      .get()
    const subscriptions = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const { uid, email, createdAt } = doc.data()

        return {
          id: doc.id,
          uid,
          email,
          createdAt: createdAt.toDate().toLocaleDateString(),
        }
      })
    )
    setSubscriptions(subscriptions)
  }

  useEffect(() => {
    fetchSubscriptions(dateCursor)
  }, [fetchSubscriptions])

  return (
    <>
      <SearchByDateInput onChange={changeDate} onKeyPress={handleEnter} />
      <CourseList>
        {subscriptions.map((subscription) => {
          const { id, uid, email, createdAt } = subscription

          return (
            <CourseItem key={`${id}-${uid}`}>
              <div>{id}</div>
              <div>{uid}</div>
              <div>{email}</div>
              <div>{createdAt}</div>
            </CourseItem>
          )
        })}
      </CourseList>
      {subscriptions.length === LIMIT && (
        <NextButton onClick={() => fetchSubscriptions(dateCursor)}>
          Next
        </NextButton>
      )}
    </>
  )
}
